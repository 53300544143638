import { Link } from "gatsby";
import {
    Box, Button, Image, Text, TextInput
} from "grommet";
import React, { useState } from "react";
import service from '../services/auth_service';
import StandardText from "../components/elements/standard_text";
import Layout from "../components/elements/layout";
import { COLOR, FONT_SIZE } from "../services/utils";

const ForgotPasswordPage = ({ logo }) => {

    const [username, setUserName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [successful, setSuccessful] = useState();

    const sendEmail = () => {
        if (!username || !username.includes('@') || !username.includes('.') || username.length < 6) {
            setError('Identifiant non valid'); return;
        }

        setError('');
        setLoading(true);
        service.forgotPassword(username)
            .then(_ => {
                setSuccessful(true);
            }).catch(err => {
                setLoading(false);
                const message = err.response.data?.error?.message;
                setError(message || 'Unexpected error');
                console.log(err.response);
            });
    }

    const inputStyle = { background: '#EDEDED', fontSize: FONT_SIZE.medium, borderRadius: 10, border: 'none' };
    return (
        <Layout style={{ justifyContent: "center", alignContent: "center" }}
        >
            <Box
                justify="center"
                as="main"
                flex
                overflow="auto"
                width="large"
                direction="column"
                alignContent="center"
                alignSelf="center"
            >
                <Box width="large" align="center" justify="between" gap="medium"
                    style={{minHeight: 'auto'}}
                >
                    <Box width={'large'} justify="center" align="center">
                        <Image src={"https://meor.fr/lib_GoFZnqrgTVOIKMVV/jk7bgswsy6fhu9vt.png"} />
                    </Box>

                    <Text size="small" style={{ color: '#dc3545' }} textAlign="center">{error}</Text>
                    {successful ?
                        <Box justify="center" align="center" gap="medium"
                            style={{ minHeight: 'auto' }}
                        >
                            <StandardText
                                style={{ fontSize: FONT_SIZE.small, lineHeight: 'normal' }}
                                bold
                                color={COLOR.dark_blue}
                                textAlign='center'
                                label={"Si votre compte existe, un lien de réinitialisation de mot de passe vous a été envoyé par mail. Si vous ne trouvez pas le mail, taper \"reset password\" dans votre barre de recherche de mail."}
                            />
                            <StandardText
                                style={{ fontSize: FONT_SIZE.small, lineHeight: 'normal' }}
                                bold
                                textAlign='center'
                                label={"If your account exists, a password resetting email will be sent to your email. If you do not find the mail, tape \"reset password\" in your mail search bar"}
                            />
                            <Box width="large" align="center" justify="center"
                                style={{ minHeight: 'auto' }}
                            >
                                <Link to="/login">
                                    <Text style={{ color: '#707070', fontSize: FONT_SIZE.large }} textAlign="center">Connection</Text>
                                </Link>
                            </Box>
                        </Box> :
                        <Box width="large" gap="medium" justify="center" align="center">
                            <TextInput
                                placeholder="Email "
                                onChange={event => setUserName(event.target.value)}
                                style={inputStyle}
                            />
                            <Box width="medium" alignSelf="center">
                                <Button size="xxlarge"
                                    onClick={sendEmail} disabled={loading}
                                    label="Valider"
                                    style={{ background: 'brand' }}
                                />
                            </Box>
                            <Box width="medium" align="center" justify="center">
                                <Link to="/login">
                                    <Text style={{ color: '#707070', fontSize: FONT_SIZE.medium }} textAlign="center">Connection</Text>
                                </Link>
                            </Box>
                        </Box>
                    }
                </Box>

            </Box>
        </Layout>
    );
}

export default ForgotPasswordPage;